.featured-post {
  display: block;

  top: -26px;
  left: 0;
  text-align: center;
  line-height: 1.5;
}

.featured-post h2 {
  padding: 15px 12px 5px 12px;
  font-size: 1.1rem;
  letter-spacing: 0.2px;
  text-align: center;
}

.feature-title-span {
}

.featured-post p {
  padding: 5px 20px 10px 20px;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-light);
  line-height: 1.5;
}

.featured-post img {
  width: 100%;
  height: 275px;
  object-fit: cover;
}

@media only screen and (min-width: 1200px) {
  .featured-post {
    width: 100%;
    padding-bottom: 50px;
    margin-bottom: 50px;
    position: relative;
  }

  .featured-details {
    width: 40%;
    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translateX(-50%);

    padding-bottom: 25px;
    z-index: 1;
  }

  .featured-post img {
    height: 600px;
    width: 50%;
    z-index: -1;
    margin: auto;
    box-sizing: border-box;
  }

  .featured-post h2 {
    font-size: 1.7rem;
  }

  .featured-post p {
    font-size: 1.1rem;
  }
}
