:root {
  --primary-color: #00344f;
  --secondary-color: #de1932;
  --background-color: #f4f4f4;
  --text-color: #333;
  --light-gray: #ebebeb;
  --dark-gray: #0b080b;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --font-size-small: 0.9rem;
  --font-size-medium: 1rem;
  --font-weight-light: 300;
  --font-weight-medium: 600;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  box-shadow: 0 0 10px var(--shadow-color);
  width: 100%;
  z-index: 999;
}

.logo-W {
  color: var(--secondary-color);
  font-weight: bold;
}

header img {
  margin-top: 10px;
  width: 150px;
  object-fit: cover;
}

header .right ul {
  display: none;
}

.title-container {
  font-size: 1.5rem;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: var(--primary-color);
  margin: 6px 0;
  transition: 0.4s ease;
}

.bar1.change {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.bar2.change {
  opacity: 0;
}

.bar3.change {
  -webkit-transform: rotate(45deg) translate(-10px, -10px);
  transform: rotate(45deg) translate(-6px, -8px);
}

.hamburger {
  z-index: 999;
  cursor: pointer;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 30%;
  background-color: var(--background-color);

  top: 0;
  right: -100%;
  transition: 0.5s;
}

.mobile-nav li {
  list-style: none;
  margin: 20px 0;
}

.mobile-nav a {
  text-decoration: none;
  color: #333;
  font-size: 1.5rem;
  margin: 20px 0;
}

.mobile-nav.active {
  right: 0;
}

@media only screen and (min-width: 1000px) {
  header {
    padding-inline: 50px;
  }

  header .right ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  header .right ul li {
    margin: 0 10px;
    list-style: none;
  }

  header .right ul li a {
    font-size: 1.3rem;
    text-decoration: none;
    list-style: none;
    color: var(--text-color);
  }

  header .right ul li a:hover {
    color: var(--secondary-color);
    text-decoration: underline;
  }
}
